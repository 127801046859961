import { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Grid,
  makeStyles,
  Container,
} from '@material-ui/core'
import { useSelector } from "react-redux";
import axios from "axios";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from "moment";

import useQuery from "../../../components/custom-hook/use-query";
import CustomTable, { paginationConfig } from '../../../components/custom-table';
import { usePopup } from '../../../components/context/popup-context';
import DateTimeRangePicker from "../../../components/date-time-range-picker";
import { purchasesTaxTableSchema, salesTaxTableSchema, withholdingTaxTableSchema } from './table-schema';
import { getDefaultFilterBetweenDate } from "../../utility";


const useStyles = makeStyles(( theme ) => ({
    container: {
      // maxHeight: 500,
    },
    button: {
      textTransform: 'capitalize',
      margin: `0px ${theme.spacing(1)}px`
    },
    actionButton:{
      color: theme.palette.error.main,
    },
    avatarStyle:{
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
    },
    tabContainer: {
      margin: `${theme.spacing(3)}px 0`,
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        boxShadow: theme.shadows[1],
      },
      '& .MuiTab-root:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        boxShadow: theme.shadows[1],
      },
      '& .MuiTabs-indicator	': {
        display: 'none',
      },
    },
    tab: {
      background: theme.palette.grey[100],
      transition: "all 0.3s ease-in-out",
      marginRight: "5px",
    },
    buttonContainer : {
      margin: `${theme.spacing(1)}px 0`
    },
    VATReportsTable: {
      width: "100%",
      // borderCollapse: "collapse",
      "& td,& th" : {
        border: '1px solid grey',
      },
      '& thead' : {
        '& .MuiTableCell-sizeSmall' : {
          padding: `0 ${theme.spacing(1)}px`,
        }
      }
    },
    tableHeadStyle: {
      backgroundColor: theme.palette.primary.main,
      "& .MuiTableCell-head":{
        color: 'white',
        border: `1px solid lightGray`,
      },
      // "& .MuiTableCell-stickyHeader":{
      //   color: 'white',
      //   border: `1px solid lightGray`,
      //   backgroundColor: theme.palette.primary.main,
      // },
    }
  }));


const VatReports = () => {
  const classes = useStyles();
  const [value, setValue] = useState("SALE");
  const [vatReportsTableSchema, setVatReportsTableSchema] = useState(salesTaxTableSchema);
  const { showSpinner, hideSpinner } = usePopup();
  const { organization } = useSelector((state) => state.organizationReducer);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPageOptions[0],
    count: 0,
  });
  const { data, error, query, loading } = useQuery(`${process.env.REACT_APP_SERVER_URI}/reports/sale`)
  // const [open, setOpen] = useState(true);

  const [date, setDate] = useState({
    startDate: getDefaultFilterBetweenDate().startDate, 
    endDate: getDefaultFilterBetweenDate().endDate,
  });
 
  // const toggle = () => setOpen(!open);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === "SALE") {
      setVatReportsTableSchema(salesTaxTableSchema)
    } else if ( newValue === "PURCHASE" ) {
      setVatReportsTableSchema(purchasesTaxTableSchema)
    } else if ( newValue === "WITHHOLDING" ) { 
      setVatReportsTableSchema(withholdingTaxTableSchema)
    }
  };

  const handleFetchVATData = async ({
    pageProps = pagination.page,
    perPageProps = pagination.rowsPerPage,
    startDateProps = date?.startDate,
    endDateProps = date?.endDate,
  } = {}) => {
    try{
      await query({
        page: pageProps,
        perPage: perPageProps,
        invoiceType: value,
        startDate: startDateProps?.getTime(),
        endDate: endDateProps?.getTime(),
      });
      setPagination( pre => ({
        ...pre,
        page: pageProps,
        rowsPerPage: perPageProps,
      }) )
    } catch ( err ) {
      console.error( err );
    }
  }

  useEffect( ( ) => {
    handleFetchVATData( );
  }, [organization, value]);

  useEffect( ( ) => {
    if( data ){
      setPagination( pre => ({ ...pre,  count: data.totalDocs  }) );
    }
  }, [data] )

  const handleExportVATReports = async ( ) => {
    const headers = {'Content-Type': 'blob'};
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URI}/reports/sale`,
      responseType: 'arraybuffer', // important
      withCredentials: true,
      headers,
      params: {
        startDate: date.startDate.getTime(),
        endDate: date.endDate.getTime(),
        exportFile: true,
      }
    };
    try {
      showSpinner();
      const response = await axios(config);
      
      const outputFilename = `VAT-Reports-${moment(Date.now()).format('DD-MMMM-YYYY')}.xlsx`;

      const url = URL.createObjectURL(new Blob([response.data]));
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.setAttribute('download', outputFilename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();

     setTimeout(function() {
       // clear 
       document.body.removeChild(tempLink);
       window.URL.revokeObjectURL(url);
     }, 200)
    } catch ( err ) {
      console.error( err, 'err' )
    } finally {
      hideSpinner()
    }
  }
  
  const handleChangeVATPage = ( event, page ) => {
    handleFetchVATData( null, page )
  }

  const handleChangeVATRowsPerPage = ( event ) => {
    handleFetchVATData( null, 0, parseInt(event.target.value, 10) )
  }

  return (
    <Container>
      <Grid container item xs={12} justify="flex-end" className={classes.buttonContainer}>
        <Grid item xs>
          <h1>VAT Reports</h1>
        </Grid>
        <Grid item xs={4} style={{alignSelf: 'center'}}>
          <DateTimeRangePicker
            date={date}
            elevation={2}
            setDate={setDate}
            onAfterChange={( props ) =>{
              return handleFetchVATData({
                pageProps: 0,
                startDateProps: props?.startDate,
                endDateProps: props?.endDate,
              })
            }}
          />
        </Grid>
        <Grid item xs="auto" style={{alignSelf: 'center'}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<FileCopyIcon />}
            onClick={() => handleExportVATReports()}
          >
            Export
          </Button>
        </Grid>
      </Grid>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabContainer}
      >
        <Tab className={classes.tab} label="Sales Tax" value="SALE"/>
        <Tab className={classes.tab} label="Purchases Tax" value="PURCHASE"/>
        <Tab className={classes.tab} label="Withholding Tax" value="WITHHOLDING"/>
        {/* <Tab className={classes.tab} label="Withholdings Tax" /> */}
      </Tabs>
      <CustomTable
        schema={vatReportsTableSchema}
        className={classes.VATReportsTable}
        dataList={data?.docs}
        loading={loading}
        error={error}
        // deleteIdentifier=""
        handleDeleteMultiple={() => {}}
        disableCheckBox
        disableHover
        tableHeadClassName={classes.tableHeadStyle}
        paginationProps={{
          count: pagination.count,
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page,
          onChangePage: handleChangeVATPage,
          onChangeRowsPerPage: handleChangeVATRowsPerPage,
        }}
      />
    </Container>
  );
};

export default VatReports;
